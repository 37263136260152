<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.refresh()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit"></edit-dialog>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="m.list" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./views/changeLogView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      m: {
        title: "История изменений в системе",
        api: "/mechti/change_log",
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 50 },
          { name: "published_on", title: "Опубликовано", type: "datetime", sortable: true, width: 120 },
          { name: "name", title: "Наименование", type: "string", sortable: true, width: 450 },
        ],
        form: [
          { name: "name", title: "Наименование", type: "string", validator: ["req"], autofocus: true },
          { name: "accessRoles", title: "для роли..", type: "selectAccess2", accessType: "role", validator1: ["req"], default: [] },
          { name: "content", title: "Содержимое", type: "ckeditor5", validator: ["req"], rows: -1 },
          { name: "videos", title: "Видео-файлы", type: "filesdatavideo", table_name: "changeLog" },
          { name: "filesRemove", default: [], hidden: true },
          { name: "filesAdd", default: [], hidden: true },
          { name: "access_all", title: "Общий доступ", type: "switcher", default: 0 },
          { name: "status", title: "Публиковать", type: "select", options: "yesNo", default: 0 },
        ],

        config: {
          form: {
            title: "Change Log",
            fields: "name,accessRoles,content,videos,filesRemove,filesAdd,status,access_all",
            fieldsRO: "",
            fieldsForm: "name,status#6,access_all#6,accessRoles,content,videos",
          },
        },
      },
      dataTable: [],

      url: "/mechti/change_log",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  computed: {},
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
        this.$store.dispatch("state/badges", "changeLog");
      }
    },
  },
  created() {
    this.$root.title = this.m.title;
    // this.getData();
  },

  methods: {
    async getData() {},

    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
